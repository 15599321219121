<template>
  <v-main>
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pb-0 pb-0">
        <v-card>
          <v-card-text>
            <v-row gutters="2" justify="center">
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="allmessages"
                  :item-class="readed_class"
                  :footer-props="{
                    itemsPerPageOptions: [-1, 30, 50, 100],
                  }"
                >
                  <template v-slot:[`item.messageId`]="{ item }">
                    <router-link
                      :to="{
                        name: 'messages-read',
                        params: { id: item.messageId },
                      }"
                    >
                      <v-icon class="mr-2" color="primary" dark v-bind="attrs">
                        mdi-eye
                      </v-icon>
                    </router-link>
                    <span class="svg-icon svg-icon-md svg-icon-danger">
                      <v-icon
                        class="mr-2"
                        color="red"
                        v-on:click="removeMessage(item.messageId)"
                        dark
                        v-bind="attrs"
                      >
                        mdi-delete
                      </v-icon>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="confirmDeleteMessage()">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ALL_MESSAGES } from "@/core/services/store/messages/messages.module";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "messages",
  components: {},
  data() {
    return {
      isShowDialogDelete: false,
      deleteId: null,
      headers: [
        {
          text: this.$t("MESSAGES.PRODUCT"),
          align: "start",
          sortable: true,
          value: "Product",
        },

        {
          text: this.$t("MESSAGES.FIRSTNAME"),
          value: "firstname",
        },
        {
          text: this.$t("MESSAGES.LASTNAME"),
          value: "lastname",
        },
        {
          text: this.$t("MESSAGES.MESSAGE"),
          value: "messageDescription",
          sortable: false,
        },
        {
          text: this.$t("MESSAGES.DATE"),
          value: "created_at",
          sortable: true,
        },
        {
          text: this.$t("MESSAGES.ACTIONS"),
          value: "messageId",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MESSAGES.TITLE") },
    ]);
    this.$store.dispatch(GET_ALL_MESSAGES);
  },
  computed: {
    ...mapGetters(["allmessages"]),
  },
  methods: {
    ...mapActions(["deleteMessage"]),
    readed_class(item) {
      if (!item.messageCampusViewed) {
        return "grey_row";
      }
    },
    removeMessage(messageId) {
      this.isShowDialogDelete = true;
      this.deleteId = messageId;
    },
    confirmDeleteMessage() {
      this.isShowDialogDelete = false;
      this.deleteMessage(this.deleteId).then(() => {
        this.$store.dispatch(GET_ALL_MESSAGES);
      });
    },
  },
};
</script>

<style lang="scss">
.grey_row {
  background-color: rgb(220, 220, 220);
}
</style>
